import { getIcon } from '../../utils/icon';
import { useIsMobile } from '../../hooks/useIsMobile.js';
import React, { useState } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { ShareButton, Label, SocialShareComponent } from './SocialShare.styles';

const SocialShare = (props) => {
  const { fields = {} } = props;

  const {
    shareLabel = {},
    shareMedia = {},
    shareText = {},
    shareTitle = {},
    shareUrl = {},
    downloadLabel = {},
  } = fields;

  const isMobile = useIsMobile('tabletLandscape');

  const handleClick = async () => {
    if (navigator.share && isMobile) {
      const blob = await (await fetch(shareMedia?.value.src)).blob();

      let fileName = shareMedia?.value?.src?.split('/')?.at(-1);
      fileName = fileName?.split(/[#?]/)?.[0];

      const video = new File([blob], fileName, {
        type: blob.type,
      });

      navigator
        .share({
          title: shareTitle?.value,
          text: shareText?.value,
          url: shareUrl?.value,
          files: [video],
        })
        .then(() => {
          if (!isServer()) {
            window.dataLayer.push({ event: 'socialShare' });
          }
        })
        .catch((err) => {
          if (!err.toString().includes('AbortError')) {
            alert('There was an issue sharing this resource');
          }
          console.log('Share failed', err);
        });
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.download = shareTitle?.value;
      a.href = shareMedia?.value.src;
      a.click();

      if (!isServer()) {
        window.dataLayer.push({ event: 'downloadMedia' });
      }
    }
  };

  return (
    <>
      <SocialShareComponent>
        <ShareButton
          onClick={() => {
            handleClick();
          }}
        >
          <div className="icon-container">
            {getIcon(isMobile && navigator.share ? 'share' : 'downloadShare')}
          </div>

          <Label>
            {isMobile && navigator.share
              ? shareLabel?.value
              : downloadLabel?.value}
          </Label>
        </ShareButton>
      </SocialShareComponent>
    </>
  );
};

export default SocialShare;
