import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const FeatureCardsComponent = styled.div`
  overflow: hidden;
  min-height: 10vh;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  padding: ${props => props.theme.px(30)};
  visibility: ${props => (props.isMounted ? 'visible' : 'hidden')};

  ${breakpoints.tabletLandscape} {
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding-top: 0;
    padding-right: 0;
    padding-left: ${props => props.theme.px(64)};
  }

  ${breakpoints.desktopMedium} {
    padding-top: 0;
    padding-right: 0;
    padding-left: ${props => props.theme.px(114)};
  }

  .limitedWidth {
    max-width: ${props => props.theme.px(1700)};
  }
`;

export const Layout = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.px(20)};

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
  }

  .content {
    z-index: 1;
    position: relative;
    padding-bottom: ${props => props.theme.px(5)};
    ${breakpoints.tabletLandscape} {
      max-width: ${props => props.theme.px(500)};
      padding-top: ${props => props.theme.px(143)};
      padding-bottom: ${props => props.theme.px(10)};
    }

    ${breakpoints.desktopSmall} {
      max-width: ${props => props.theme.px(600)};
    }

    ${breakpoints.desktopLarge} {
      max-width: ${props => props.theme.px(885)};
    }
  }
`;

export const Title = styled.h1`
  margin-bottom: 0;
  font-size: 52px;
  line-height: 57px;
  width: 100%;
  padding-right: ${props => props.theme.px(50)};

  ${breakpoints.tabletLandscape} {
    font-size: 100px;
    letter-spacing: -3px;
    line-height: 100px;
    padding-bottom: 26.83px;
  }

  ${breakpoints.desktopLarge} {
    font-size: 150px;
    line-height: 145px;
    padding-bottom: 26.83px;
  }
`;

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  padding-right: ${props => props.theme.px(50)};

  ${breakpoints.tabletLandscape} {
    font-size: 22px;
    line-height: 32px;
  }

  ${breakpoints.desktopLarge} {
    font-size: 28px;
    line-height: 45px;
  }
`;

export const ListContainer = styled.div`
  margin-top: ${props => props.theme.px(33)};

  ul {
    padding: 0;
    margin-top: ${props => props.theme.px(26)};
    margin-bottom: 0;
  }

  ${breakpoints.desktopSmall} {
    margin-top: ${props => props.theme.px(50)};

    ul {
      ${props =>
    props.split &&
    css`
          display: flex;
          flex-wrap: wrap;
        `};
    }
  }
`;

export const SwipeText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.setFont('visualCardSwipeMessage')}
  margin-bottom: 0;
  padding-bottom: ${props => props.theme.px(25)};
  color: ${props => props.theme.colors.grays[500]};
  margin-top: 0;

  ${breakpoints.tabletLandscape} {
    display: none;
  }

  svg {
    width: 15px;
    margin-right: ${props => props.theme.space(0.5)};
    fill: ${props => props.theme.colors.grays[500]};
  }
`;

export const CtaListItem = styled.li`
  list-style-type: none;
  margin-bottom: ${props => props.theme.px(10)};

  a {
    font-size: ${props => props.theme.px(16)};
    background-color: ${props => props.theme.colors.named.white};
  }

  ${breakpoints.desktopSmall} {
    margin-bottom: ${props => props.theme.px(20)};
    max-width: ${props => props.theme.px(428)};

    a {
      font-size: ${props => props.theme.px(21)};
    }
  }
`;

export const AdWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: ${props => props.theme.px(9)};
  padding-bottom: ${props => props.theme.px(50)};

  ${breakpoints.tabletPortrait} {
    justify-content: right;
  }

  ${breakpoints.tabletLandscape} {
    position: absolute;
    right: 0;
    padding: 0;
    bottom: ${props => props.theme.px(41)};
  }

  ${breakpoints.desktopSmall} {
    bottom: ${props => props.theme.px(126)};
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: auto;

  ${breakpoints.tabletLandscape} {
    max-width: ${({ hasVideo }) => (hasVideo ? 'unset' : '750px')};
    margin-left: auto;
  }

  ${breakpoints.desktopSmall} {
    max-width: ${({ hasVideo }) => (hasVideo ? 'unset' : '650px')};
  }

  ${breakpoints.desktopLarge} {
    max-width: ${({ hasVideo }) => (hasVideo ? 'unset' : '875px')};
  }

  img {
    width: 100%;
    height: 100%;

    ${breakpoints.tabletLandscape} {
      width: auto;
    }

    ${breakpoints.desktopSmall} {
      width: 100%;
    }
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  .react-player {
    position: relative;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  ${breakpoints.desktopSmall} {
    height: 120%;
  }
`;

export const Pattern = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  margin-right: 0px;
  z-index: 2;

  svg {
    position: absolute;
    ${props => patternLayout(props.patternName, props.alignment).mobile}
    fill: ${props => props.theme.colors.named.white};

    path,
    g {
      fill: ${props => props.theme.colors.named.white};
    }

    ${breakpoints.tabletLandscape} {
      ${props => patternLayout(props.patternName, props.alignment).desktop}
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  box-sizing: border-box;
  grid-gap: 5px;

  ${breakpoints.tabletLandscape} {
    grid-gap: 1rem;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
`;

export const FeatureCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding-bottom: ${props => props.theme.px(41)};
  padding-top: ${props => props.theme.px(5)};

  ${breakpoints.tabletLandscape} {
    max-width: 100%;
    padding-right: ${props => props.theme.px(64)};
    padding-bottom: ${props => props.theme.px(10)};
    margin-left: 0;
    padding-top: ${props => props.theme.px(30)};
  }

  ${breakpoints.desktopSmall} {
    padding-top: ${({ hasVideo, theme: { px } }) => (hasVideo ? px(140) : px(20))};
  }

  ${breakpoints.desktopExtraLarge} {
    padding-top: ${({ hasVideo, theme: { px } }) => (hasVideo ? px(170) : px(20))};
  }

  .scroll-container {
    overflow-y: hidden;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-right: ${props => props.theme.px(15)};
    padding-left: ${props => props.theme.px(5)};
  }

  .scroll-item {
    scroll-snap-align: center;
  }

  .scroll-container[data-layoutmethod='grid'] {
    display: grid;
    grid: auto / auto-flow min(21rem, 80vw);
    grid-gap: 1em;
    align-items: center;
  }

  .scroll-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${props => props.theme.px(8)};
  }
`;

export const MobileViewCTAContainer = styled.div`
  padding-top: ${props => props.theme.px(10)};
  padding-bottom: ${props => props.theme.px(10)};
`;

const patternLayout = name => {
  const rightPattern = {
    'the-steps': {
      desktop: css`
        top: 10%;
        right: 0%;
        height: 50%;
        transform: rotate(180deg);
      `,
    },
  };

  const layout = rightPattern;
  if (name in layout) return layout[name];

  return layout['twenty-six-degree-mark'];
};
