import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  TeaserComponent,
  Layout,
  TeaserTextPattern,
  RevealComponent,
  TeaserText,
  TeaserTextLine,
  CTAWrapper,
  AdWrapper,
  RevealLayout,
  RevealText,
  LayerImage,
} from './ResultReveal.styles.js';
import CTA from '../cta/CTA';
import { useIsMobile } from '../hooks/useIsMobile.js';
import useWindowSize from '../hooks/useWindowSize';
import useHeaderAdsVisible from '../hooks/useHeaderAdsVisible';
import { useState } from 'react';
import { handleCtaMappingByTheme } from '../cta/ctaMapper.js';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const ResultReveal = props => {
  const {
    fields: { teaserTitle = {}, ctaText = {}, showResult = {}, resultTitle = {}, layerImage = {} } = {},
    rendering,
    sitecoreContext: { pageEditing = false } = {},
  } = props;

  const pageLock = useRef(null);

  const [reveal, setReveal] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [showTeaser, setShowTeaser] = useState(true);
  const [scrollLock, setScrollLock] = useState(!pageEditing);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [headerAdsHeight, setHeaderAdsHeight] = useState(0);

  const wholeHeaderHeight = headerHeight + headerAdsHeight;

  const { width: windowWidth } = useWindowSize();
  const headerAdsVisible = useHeaderAdsVisible();

  const isMobile = useIsMobile('mobileLarge');
  const isTablet = useIsMobile('tabletLandscape', -1);
  const isSmallDesktop = useIsMobile('desktopLarge');

  const [totalRows, setTotalRows] = useState(4);

  const highlightRowIndex =
    typeof window !== 'undefined' && isTablet ? totalRows - 2 : Math.floor(totalRows / 2);

  const textRepeats = 5;
  const textHighlightIndex = Math.floor(textRepeats / 2);

  const revealAnimation = useAnimation();
  const textAnimation = useAnimation();
  const layerImageAnimation = useAnimation();

  useEffect(() => {
    if (!isServer() && !pageEditing) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsMounted(true);
      disableBodyScroll(pageLock.current);
      setScrollLock(true);
    }
  }, []);

  useEffect(() => {
    const headerAdsEl = document.querySelector('#header-ads');
    const headerEl = document.querySelector('header.main-header');

    if (headerAdsEl) {
      setHeaderAdsHeight(headerAdsEl?.clientHeight ?? 0);
    }

    if (headerEl) {
      setHeaderHeight(headerEl?.clientHeight ?? 0);
    }
  }, [windowWidth, headerAdsVisible]);

  useEffect(() => {
    if (reveal && !isTablet) desktopAnimate();
    if (reveal && isTablet) mobileAnimate();
  }, [reveal]);

  useEffect(() => setTotalRows(isTablet ? 4 : 15), [isTablet]);

  useEffect(() => {
    if (reveal) {
      setTimeout(() => clearAllBodyScrollLocks(), 3500);
    }
  }, [reveal]);

  const desktopAnimate = async () => {
    await revealAnimation.start({
      opacity: 1,
      marginTop: 0,
    });

    setShowTeaser(false);
    const duration = 0.4;
    await textAnimation.start(i => ({
      opacity: [null, 1, 1, 0.17],
      transition: {
        delay: i * duration,
        times: [0, 0, 1, 1],
        duration: duration,
      },
    }));

    textAnimation.start({
      opacity: [null, 1, 1],
      transition: { times: [0, 0, 1] },
    });

    await layerImageAnimation.start({
      opacity: [null, 1, 1],
      transition: { times: [0, 0, 1] },
    });

    clearAllBodyScrollLocks();
    setScrollLock(false);
    await revealAnimation.start({ marginTop: '-100vh' });

    document.getElementsByTagName('body')[0].classList.remove('body-hidden');
    setReveal(false);
  };

  const mobileAnimate = async () => {
    const n = getRevealRepeatCount();
    textAnimation.start(i => ({
      opacity: Math.floor(n / 2) - 1 === i ? 1 : 0.17,
      transition: { duration: 0.15 },
    }));

    await revealAnimation.start({
      opacity: [null, 1],
      marginTop: [0, 0],
      transition: { duration: 0.15 },
    });

    setShowTeaser(false);
    await revealAnimation.start({
      opacity: [1, 0],
      transition: {
        delay: 1,
        duration: 0.15,
      },
    });

    clearAllBodyScrollLocks();
    setScrollLock(false);
    setReveal(false);

    document.getElementsByTagName('body')[0].classList.remove('body-hidden');
  };

  const getRevealRepeatCount = () => {
    if (!isServer()) {
      const height = window.innerHeight;

      const lineHeight = isMobile ? 65 : isTablet ? 110 : isSmallDesktop ? 200 : 291;
      return Math.ceil(height / lineHeight);
    } else {
      return 3;
    }
  };

  const handleClick = () => {
    if (!isServer()) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (showResult?.value) {
      setReveal(true);
    } else {
      setShowTeaser(false);
    }
  };

  const repeatN = n => [...Array(n)];

  const teaserComponent = (
    <TeaserComponent
      wholeHeaderHeight={wholeHeaderHeight}
      pageEditing={pageEditing}
      isMounted={isMounted}
      className="reveal"
    >
      <Layout hideScroll={scrollLock} wholeHeaderHeight={wholeHeaderHeight} pageEditing={pageEditing}>
        <TeaserTextPattern headerAdsHeight={headerAdsHeight} wholeHeaderHeight={wholeHeaderHeight}>
          {repeatN(totalRows).map((e, i) => (
            <TeaserTextLine offset={i - highlightRowIndex} key={i}>
              <p className="h1">
                {repeatN(textRepeats).map((e, j) => (
                  <TeaserText
                    as={j === textHighlightIndex && i === highlightRowIndex ? 'h1' : 'span'}
                    highlight={j === textHighlightIndex && i === highlightRowIndex}
                    aria-hidden={!(j === textHighlightIndex && i === highlightRowIndex)}
                    key={j}
                  >
                    {teaserTitle?.value}
                  </TeaserText>
                ))}
              </p>
              {i === highlightRowIndex && !isTablet && ctaText?.value && (
                <CTAWrapper headerAdsHeight={headerAdsHeight} wholeHeaderHeight={wholeHeaderHeight}>
                  <CTA
                    type="a"
                    variant={handleCtaMappingByTheme('london-marathon', 'winter-sky')}
                    label={ctaText?.value}
                    ariaLabel={ctaText?.value}
                    onClick={handleClick}
                  />
                </CTAWrapper>
              )}
            </TeaserTextLine>
          ))}
          {isTablet && ctaText?.value && (
            <CTAWrapper headerAdsHeight={headerAdsHeight} wholeHeaderHeight={wholeHeaderHeight}>
              <CTA
                type="a"
                variant={handleCtaMappingByTheme('london-marathon', 'winter-sky')}
                label={ctaText?.value}
                ariaLabel={ctaText?.value}
                fullwidth={isMobile ? 1 : 0}
                onClick={handleClick}
              />
            </CTAWrapper>
          )}
        </TeaserTextPattern>
        {rendering?.placeholders?.['jss-mpu-ads']?.length > 0 && (
          <AdWrapper className="limitedWidth">
            <div>
              <Placeholder name="jss-mpu-ads" rendering={rendering} />
            </div>
          </AdWrapper>
        )}
      </Layout>
    </TeaserComponent>
  );

  const revealComponent = (
    <RevealComponent isMounted={isMounted} className="reveal">
      <motion.div
        initial={{
          opacity: 0,
          marginTop: '100%',
          height: '100%',
        }}
        animate={revealAnimation}
        transition={{ ease: 'easeOut' }}
      >
        <RevealLayout>
          <motion.div initial={{ opacity: 0 }} animate={layerImageAnimation} transition={{ ease: 'easeOut' }}>
            <LayerImage src={layerImage?.value?.src} alt={layerImage?.value?.alt} />
          </motion.div>
          {repeatN(getRevealRepeatCount()).map((e, k) => (
            <motion.div
              key={k}
              custom={k}
              initial={{ opacity: 0.17 }}
              animate={textAnimation}
              transition={{ ease: 'easeOut' }}
            >
              <RevealText>{resultTitle?.value}</RevealText>
            </motion.div>
          ))}
        </RevealLayout>
      </motion.div>
    </RevealComponent>
  );

  return (
    <div ref={pageLock}>
      <AnimatePresence>
        {showTeaser && (
          <motion.div exit={{ opacity: 0 }} transition={{ ease: 'easeOut', duration: 0.5 }}>
            {teaserComponent}
          </motion.div>
        )}
      </AnimatePresence>
      {reveal && revealComponent}
    </div>
  );
};

ResultReveal.propTypes = {
  fields: PropTypes.shape({
    teaserTitle: PropTypes.object,
    ctaText: PropTypes.object,
    showResult: PropTypes.object,
    resultTitle: PropTypes.object,
    layerImage: PropTypes.object,
  }),
  params: PropTypes.object,
  rendering: PropTypes.object,
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
  }),
};

export default ResultReveal;
