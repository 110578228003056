import { breakpoints } from '../global-styles/breakpoints';
import styled, { css } from 'styled-components';

export const BallotHeroComponent = styled.div`
  overflow: hidden;
  visibility: ${props => (props.isMounted ? 'visible' : 'hidden')};
  background-color: ${props =>
    props.greyBackground ? props.theme.colors.grays[100] : props.theme.colors.named.white};
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  padding: ${props => props.theme.px(30)};
  opacity: ${props => (props.hide ? 0 : 1)};

  ${breakpoints.tabletLandscape} {
    &:first-of-type {
      padding-top: ${props => props.theme.px(10)};
    }
    transform: translateY(-40px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding-top: 0;
    padding-right: 0;
    padding-left: ${props => props.theme.px(64)};
  }

  ${breakpoints.desktopMedium} {
    padding-top: 0;
    padding-right: 0;
    padding-left: ${props => props.theme.px(114)};
  }

  ${breakpoints.desktopLarge} {
    padding-right: 0;
  }

  .limitedWidth {
    max-width: ${props => props.theme.px(1700)};
  }
`;

export const Layout = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 70vh;

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
  }

  .content {
    z-index: 1;
    position: relative;
    padding-top: 0;
    padding-bottom: ${props => props.theme.px(41)};

    ${breakpoints.tabletLandscape} {
      max-width: ${props => props.theme.px(480)};
      padding-top: ${props => props.theme.px(143)};
      padding-bottom: ${props => props.theme.px(126)};
    }

    ${breakpoints.desktopSmall} {
      max-width: ${props => props.theme.px(720)};
    }

    ${breakpoints.desktopLarge} {
      max-width: ${props => props.theme.px(880)};
    }
  }
`;

export const Title = styled.h2`
  font-size: ${props => props.theme.px(45)};
  line-height: 1.2667;

  ${breakpoints.tabletLandscape} {
    font-size: 75px;
    line-height: 75px;
  }

  ${breakpoints.desktopLarge} {
    font-size: 150px;
    line-height: 145px;
  }
`;

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 22px;

  ${breakpoints.tabletLandscape} {
    font-size: 22px;
    line-height: 32px;
  }

  ${breakpoints.desktopLarge} {
    font-size: 28px;
    line-height: 45px;
  }
`;

export const ListTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary[200]};

  ${breakpoints.desktopSmall} {
    font-size: 28px;
  }
`;

export const ShareTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary[200]};

  ${breakpoints.tabletLandscape} {
  font-size: 22px;
}

`;

export const ListContainer = styled.div`
  margin-top: ${props => props.theme.px(33)};

  ul {
    padding: 0;
    margin-top: ${props => props.theme.px(26)};
    margin-bottom: 0;
  }

  ${breakpoints.desktopSmall} {
    margin-top: ${props => props.theme.px(50)};

    ul {
      ${props =>
    props.split &&
    css`
          display: flex;
          flex-wrap: wrap;
        `};
    }
  }
`;

export const CtaListItem = styled.li`
  list-style-type: none;
  margin-bottom: ${props => props.theme.px(10)};

  a {
    font-size: ${props => props.theme.px(16)};
    background-color: ${props =>
    props.greyBackground ? props.theme.colors.grays[100] : props.theme.colors.named.white};
  }

  ${breakpoints.tabletLandscape} {
    max-width: ${props => props.theme.px(428)};
  }

  ${breakpoints.desktopSmall} {
    margin-bottom: ${props => props.theme.px(20)};
    max-width: ${props => props.theme.px(600)};

    a {
      font-size: ${props => props.theme.px(21)};
    }

    ${props =>
    props.split &&
    css`
        width: 50%;
        padding-right: 20px;
      `};
  }
`;

export const CTAWrapper = styled.div`
  padding-top: ${props => props.theme.px(10)};
`;

export const LayerImageContainer = styled.div`
  margin: ${props => props.theme.px(20)};
  position: relative;
  display: none;
  flex-grow: 1;

  ${breakpoints.tabletLandscape} {
    display: flex;
  }

  img {
    width: auto;
    height: 100%;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    max-height: 1000px;
    top: 0;
    bottom: 0;
    margin auto 0;

    ${breakpoints.tabletLandscape} {
      max-height: 1100px;
      right: 50%;
    }

    ${breakpoints.desktopSmall} {
      max-height: 1100px;
      right: 55%;
    }

    ${breakpoints.desktopLarge} {
      max-height: 1250px;
      right: 60%;
    }
  }
`;

export const AdWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: ${props => props.theme.px(9)};
  padding-bottom: ${props => props.theme.px(50)};

  ${breakpoints.tabletLandscape} {
    justify-content: right;
    position: absolute;
    right: 0;
    padding: 0;
    bottom: ${props => props.theme.px(41)};
  }

  ${breakpoints.desktopSmall} {
    bottom: ${props => props.theme.px(126)};
  }
`;

export const Pattern = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  margin-right: 0px;
  margin-left: 0px;

  svg {
    position: absolute;
    ${props => patternLayout(props.patternName, props.alignment).mobile}
    fill: ${props => props.theme.colors.named[props.patternColor]};

    path, g {
      fill: ${props => props.theme.colors.named[props.patternColor]};
    }

    ${breakpoints.tabletPortrait} {
      ${props => patternLayout(props.patternName, props.alignment).tablet}
    }

    ${breakpoints.tabletLandscape} {
      ${props => patternLayout(props.patternName, props.alignment).desktop}
    }
  }
`;

const patternLayout = (name, alignment) => {
  const leftPattern = {
    'twenty-six-degree-mark': {
      desktop: css`
        top: -12%;
        left: 12%;
        height: 20%;
        display: block;
      `,
      mobile: `display: none;`,
    },
    'the-medal': {
      desktop: css`
        top: -15.5%;
        left: 15%;
        height: 25%;
        display: block;
      `,
      mobile: `display: none;`,
    },
    'the-steps': {
      desktop: css`
        top: -12.5%;
        left: 12%;
        height: 20%;
        display: block;
      `,
      mobile: `display: none;`,
    },
    'tower-bridge': {
      desktop: css`
        top: -24%;
        left: 8%;
        height: 30%;
        display: block;
      `,
      mobile: `display: none;`,
    },
  };

  const rightPattern = {
    'twenty-six-degree-mark': {
      desktop: css`
        top: -20%;
        right: 0;
        height: 90%;
      `,
      tablet: css`
        top: 15%;
        right: -31%;
        height: 55%;
      `,
      mobile: css`
        top: 40%;
        right: -50%;
        height: 45%;
      `,
    },
    'the-medal': {
      desktopMedium: css`
        right: 0%;
      `,
      desktop: css`
        top: 10%;
        right: -5%;
        height: 50%;
      `,
      tablet: css`
        top: 45%;
        right: -30%;
        height: 30%;
      `,
      mobile: css`
        top: 45%;
        right: -65%;
        height: 30%;
      `,
    },
    'the-steps': {
      desktop: css`
        top: 15%;
        right: 0%;
        height: 30%;
        transform: rotate(360deg);
      `,
      tablet: css`
        top: 39%;
        right: -33%;
        height: 38%;
      `,
      mobile: css`
        top: 47%;
        right: -60%;
        height: 41%;
        transform: rotate(180deg);
      `,
    },
    'tower-bridge': {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
      tablet: css`
        top: 45%;
        right: -20%;
        height: 50%;
      `,
      mobile: css`
        top: 45%;
        right: -45%;
        height: 45%;
      `,
    },
  };

  const layout = alignment === 'left' ? leftPattern : rightPattern;
  if (name in layout) return layout[name];

  return layout['twenty-six-degree-mark'];
};
