import React from 'react';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import ResultRevealComponent from 'lmel-components/core/result-reveal';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ResultReveal = props => {
  return (
    <ErrorBoundary>
      <ResultRevealComponent {...props} />
    </ErrorBoundary>
  );
};

export default withSitecoreContext()(ResultReveal);
