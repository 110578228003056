import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FeatureCardsComponent,
  Layout,
  Subtitle,
  Title,
  ImageContainer,
  CtaListItem,
  VideoWrapper,
  Pattern,
  FeatureCardListContainer,
  Grid,
  Item,
  MobileViewCTAContainer,
  SwipeText,
} from "./FeatureCards.styles.js";
import { breakpointValues } from "../global-styles/breakpoints";
import { useIsMobile } from "../hooks/useIsMobile";
import { isServer } from "@sitecore-jss/sitecore-jss";
import { getIcon } from "../utils/icon";
import { getPattern } from "../utils/pattern";
import Picture from "../picture";
import CtaAlt from "../cta-alt";
import AmbientVideoInner from "../ambient-video-inner";
import FeatureCard from "../feature-card";

const FeatureCards = (props) => {
  const {
    fields: {
      title = {},
      subTitle = {},
      cta = {},
      image = {},
      videoUrl = {},
      items = [],
    } = {},
    params: { showPattern, spaceMultiplierMobile, spaceMultiplierDesktop } = {},
    translate = {},
    sitecoreContext: { pageEditing = false } = {},
  } = props;

  const mql =
    typeof window !== "undefined"
      ? window.matchMedia(`(min-width: ${breakpointValues.tabletLandscape}px)`)
      : { matches: true };

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isDesktop, setIsDesktop] = useState(mql.matches);
  const isMobile = useIsMobile("tabletPortrait");

  const handleMediaQuery = (e) => {
    if (e.matches) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };

  const t = (key) => {
    if (translate && typeof translate === "function") {
      return translate(`VisualCards_${key}`);
    }

    return key;
  };

  const swipeText = t("Swipe");

  useEffect(() => {
    if (!isServer()) {
      mql.addListener(handleMediaQuery);
      setIsMounted(true);
    }

    return () => {
      mql.removeListener(handleMediaQuery);
    };
  }, []);

  return (
    <FeatureCardsComponent
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      isMounted={isMounted}
    >
      <Layout>
        <div className="content">
          <Title>{title?.value}</Title>
          {subTitle?.value && <Subtitle>{subTitle.value}</Subtitle>}
          {cta?.value?.href && cta?.value?.text && (
            <CtaListItem key={cta?.value.text}>
              <CtaAlt cta={cta} small />
            </CtaListItem>
          )}
        </div>
        {(isDesktop || pageEditing) && (
          <>
            {showPattern === "1" && !videoPlaying && (
              <Pattern patternName={"the-steps"} alignment="right">
                {getPattern("the-steps")}
              </Pattern>
            )}
            {image && (
              <ImageContainer hasVideo={videoUrl?.value}>
                {!videoUrl?.value && <Picture field={image} />}
                {videoUrl?.value && (
                  <VideoWrapper>
                    <AmbientVideoInner
                      url={videoUrl?.value}
                      fallback={image?.value?.fallback}
                      playing={true}
                      width="200%"
                      onReadyCallback={() => setVideoPlaying(true)}
                    />
                  </VideoWrapper>
                )}
              </ImageContainer>
            )}
          </>
        )}
      </Layout>
      {items && items.length > 0 && typeof window !== "undefined" && (
        <FeatureCardListContainer hasVideo={videoUrl?.value}>
          {isDesktop && (
            <Grid>
              <>
                {items.map((featureData, featureIndex) => (
                  <Item key={`featureCard_item_${featureIndex}`}>
                    <FeatureCard
                      fields={featureData.fields}
                      key={`featureCard_data_${featureIndex}`}
                    />
                  </Item>
                ))}
              </>
            </Grid>
          )}
          {isMobile && (
            <>
              <div className="scroll-container" data-layoutmethod="grid">
                {items.map((featureData, featureIndex) => (
                  <div
                    key={`featureCard_scoll_item_${featureIndex}`}
                    className="scroll-item"
                  >
                    <FeatureCard
                      fields={featureData.fields}
                      key={`featureCard_data_${featureIndex}`}
                    />
                  </div>
                ))}
              </div>
              {swipeText && (
                <SwipeText>
                  {getIcon("swipe")} <span>{swipeText}</span>
                </SwipeText>
              )}
              {cta?.value && !isDesktop && (
                <MobileViewCTAContainer>
                  <CtaListItem key={cta.value.text}>
                    <CtaAlt cta={cta} small />
                  </CtaListItem>
                </MobileViewCTAContainer>
              )}
            </>
          )}
        </FeatureCardListContainer>
      )}
    </FeatureCardsComponent>
  );
};

FeatureCards.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.object,
    subTitle: PropTypes.object,
    cta: PropTypes.object,
    image: PropTypes.object,
    videoUrl: PropTypes.object,
    items: PropTypes.array,
  }),
  params: PropTypes.object,
  translate: PropTypes.any,
  sitecoreContext: PropTypes.object,
};

export default FeatureCards;
