import React from 'react';
import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { Wrapper, VideoWrapper } from './AmbientVideo.styles';
import useObserver from '../hooks/useObserver';
import AmbientVideoInner from '../ambient-video-inner/AmbientVideoInner';
import { useIsMobile } from '../hooks/useIsMobile.js';

const AmbientVideo = props => {
  const { fields: { url = {}, poster = {}, mobileUrl = {} } = {} } = props;
  const [isMounted, setIsMounted] = useState(false);
  const wrapperRef = useRef(null);
  const videoRef = useRef(null);
  const [play, setPlay] = useState(false);
  const componentInView = useObserver({
    ref: wrapperRef,
    options: {
      threshold: 0.6,
    },
  });

  const videoAnimation = useAnimation();
  const wrapperAnimation = useAnimation();
  const isMobile = useIsMobile('tabletPortrait');

  useEffect(() => {
    if (!isServer()) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    animateVideo(componentInView);
  }, [componentInView]);

  const animateVideo = async enlarge => {
    if (enlarge) {
      setPlay(true);
      videoAnimation.start({
        scale: 1.1,
        transition: { ease: 'easeIn', duration: 0.5 },
      });
    } else {
      videoAnimation.start({
        scale: 0.75,
        transition: { ease: 'easeIn', duration: 0.5 },
      });
      setPlay(false);
    }
  };

  return (
    <Wrapper ref={wrapperRef} animate={wrapperAnimation} isMounted={isMounted}>
      <VideoWrapper ref={videoRef} animate={videoAnimation}>
        <AmbientVideoInner
          url={isMobile ? mobileUrl?.value : url?.value}
          fallback={poster?.value?.fallback}
          playing={play}
        />
      </VideoWrapper>
    </Wrapper>
  );
};

AmbientVideo.propTypes = {
  fields: PropTypes.shape({
    poster: PropTypes.object,
    url: PropTypes.object,
    mobileUrl: PropTypes.object,
  }),
  params: PropTypes.object,
};

export default AmbientVideo;
