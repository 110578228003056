import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FeatureCardComponent, Title, ImageContainer, Subtitle } from './FeatureCard.styles.js';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useIsMobile } from '../hooks/useIsMobile.js';

const FeatureCard = props => {
  const { fields: { image = {}, hoverImage = {}, link = {}, title = {}, subTitle = {} } = {} } = props;
  const [hoverOver, setHoverOver] = useState(false);

  const isMobile = useIsMobile('tabletPortrait');

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  return (
    <FeatureCardComponent>
      <a
        onClick={() => dataLayerEvent('navFeature')}
        href={link.value?.href}
        target={link?.value?.linktype === 'external' ? '_blank' : '_self'}
        rel="noreferrer"
      >
        <ImageContainer onMouseOver={() => setHoverOver(true)} onMouseOut={() => setHoverOver(false)}>
          <img
            src={hoverOver ? image?.value?.src : hoverImage?.value?.src}
            alt={hoverOver ? image?.value?.alt : hoverImage?.value?.alt}
          />
        </ImageContainer>

        <Title>{title?.value}</Title>
        {!isMobile && subTitle?.value && <Subtitle>{subTitle.value}</Subtitle>}
      </a>
    </FeatureCardComponent>
  );
};

FeatureCard.propTypes = {
  fields: PropTypes.shape({
    image: PropTypes.object,
    hoverImage: PropTypes.object,
    link: PropTypes.object,
    title: PropTypes.object,
    subTitle: PropTypes.object,
  }),
};

export default FeatureCard;
