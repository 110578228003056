import React from 'react';
import PropTypes from 'prop-types';
import FullWidthVideoComponent from 'lmel-components/core/full-width-video';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withTranslation } from 'react-i18next';

const FullWidthVideo = props => {
  return (
    <ErrorBoundary>
      <FullWidthVideoComponent translate={props.t} {...props} pattern="twenty-six-degree-mark" />
    </ErrorBoundary>
  );
};

FullWidthVideo.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(FullWidthVideo);
