import React from 'react';
import BallotHeroComponent from 'lmel-components/core/ballot-hero';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const BallotHero = props => {
  return (
    <ErrorBoundary>
      <BallotHeroComponent {...props} />
    </ErrorBoundary>
  );
};

export default withSitecoreContext()(BallotHero);
