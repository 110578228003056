import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BallotHeroComponent,
  Layout,
  Title,
  Subtitle,
  ListTitle,
  CtaListItem,
  ListContainer,
  CTAWrapper,
  Pattern,
  LayerImageContainer,
  AdWrapper,
  ShareTitle,
} from './BallotHero.styles.js';
import CTA from '../cta/CTA';
import CtaAlt from '../cta-alt';
import { useIsMobile } from '../hooks/useIsMobile.js';
import { getPattern } from '../utils/pattern';
import { handleCtaMappingByTheme } from '../cta/ctaMapper.js';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import SocialShare from './SocialShare/SocialShare';

const BallotHero = (props) => {
  const {
    fields = {},
    params: {
      backgroundColour,
      leftPattern,
      leftPatternColour,
      rightPattern,
      rightPatternColour,
      primaryCtaColour,
      secondaryCtaColour,
      spaceMultiplierMobile,
      spaceMultiplierDesktop,
      listType,
    } = {},
    rendering,
  } = props;

  const {
    title = {},
    subTitle = {},
    primaryCta = {},
    ctaListTitle = {},
    ctas = {},
    secondaryCta = {},
    layerImage = {},
    sharePrompt = {},
    shareButton = {},
  } = fields;

  const [hide, setHide] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useIsMobile('tabletPortrait') && isMounted;
  const greyBackground = backgroundColour === 'grey';
  const splitList = listType === 'split';

  useEffect(() => {
    setHide(false);
    if (!isServer()) {
      setIsMounted(true);
    }
  }, []);

  return (
    <BallotHeroComponent
      hide={hide}
      greyBackground={greyBackground}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      isMounted={isMounted}
    >
      <Layout className="limitedWidth">
        {leftPattern && (
          <Pattern
            patternColor={leftPatternColour}
            patternName={leftPattern}
            alignment="left"
          >
            {getPattern(leftPattern)}
          </Pattern>
        )}
        {rightPattern && (
          <Pattern
            patternColor={rightPatternColour}
            patternName={rightPattern}
            alignment="right"
          >
            {getPattern(rightPattern)}
          </Pattern>
        )}
        <div className="content">
          <Title>{title?.value}</Title>

          { sharePrompt?.value &&
            <ShareTitle>{sharePrompt?.value}</ShareTitle>
          }

          {shareButton &&
            <SocialShare {...shareButton} isMounted={isMounted} />
          }

          {subTitle?.value && <Subtitle>{subTitle.value}</Subtitle>}

          {primaryCta?.value?.text && (
            <CTAWrapper>
              <CTA
                type="a"
                variant={handleCtaMappingByTheme(
                  'london-marathon',
                  primaryCtaColour
                )}
                label={primaryCta?.value?.text}
                ariaLabel={primaryCta?.value?.text}
                link={primaryCta?.value}
                fullwidth={isMobile ? 1 : 0}
                target={primaryCta?.value?.target}
              />
            </CTAWrapper>
          )}
          {ctas?.value?.length > 0 && (
            <ListContainer split={splitList}>
              {ctaListTitle?.value && (
                <ListTitle>{ctaListTitle?.value}</ListTitle>
              )}
              <ul>
                {ctas?.value.map((cta) => (
                  <CtaListItem
                    greyBackground={greyBackground}
                    split={splitList}
                    key={cta.text}
                  >
                    <CtaAlt cta={cta} small />
                  </CtaListItem>
                ))}
              </ul>
              {secondaryCta?.value?.href && secondaryCta?.value?.text && (
                <CTA
                  type="a"
                  variant={handleCtaMappingByTheme(
                    'london-marathon',
                    secondaryCtaColour
                  )}
                  label={secondaryCta?.value?.text}
                  ariaLabel={secondaryCta?.value?.text}
                  link={secondaryCta?.value}
                  fullwidth={isMobile ? 1 : 0}
                  target={secondaryCta?.value?.target}
                />
              )}
            </ListContainer>
          )}
        </div>
        {layerImage && (
          <LayerImageContainer>
            <img src={layerImage?.value?.src} alt={layerImage?.value?.alt} />
          </LayerImageContainer>
        )}
        {rendering?.placeholders?.['jss-mpu-ads']?.length > 0 && (
          <AdWrapper>
            <Placeholder name="jss-mpu-ads" rendering={rendering} />
          </AdWrapper>
        )}
      </Layout>
    </BallotHeroComponent>
  );
};

BallotHero.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.object,
    subTitle: PropTypes.object,
    primaryCta: PropTypes.object,
    ctaListTitle: PropTypes.object,
    ctas: PropTypes.object,
    secondaryCta: PropTypes.object,
    layerImage: PropTypes.object,
  }),
  params: PropTypes.object,
  rendering: PropTypes.object,
};

export default BallotHero;
