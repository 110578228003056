import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useHeaderAdsVisible = () => {
  const [headerAdsVisible, setHeaderAdsVisible] = useState(!(Cookies.get('adsHidden') === 'true'));

  useEffect(() => {
    const adQuery = document.querySelector('#header-ads');
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          setHeaderAdsVisible(!mutation.target.classList.contains('hidden'));
        }
      });
    });

    if (adQuery) {
      observer.observe(document.querySelector('#header-ads'), {
        attributes: true,
      });
    }
  }, []);

  return headerAdsVisible;
};

export default useHeaderAdsVisible;
