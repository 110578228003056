import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const FeatureCardComponent = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.px(10)};

  &:hover {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

export const Title = styled.p`
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 0px;
  font-weight: 300;
  margin-top: 0px;
  padding-top: ${props => props.theme.px(1)};
  height: 40px;

  ${breakpoints.tabletLandscape} {
    font-size: 14px;
  }

  ${breakpoints.desktopLarge} {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const Subtitle = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: ${props => props.theme.colors.grays[300]};
  margin-top: 0px;

  ${breakpoints.tabletLandscape} {
    font-size: 12px;
  }

  ${breakpoints.desktopLarge} {
    font-size: 16px;
    line-height: 29px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    aspect-ratio: 1;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-template-rows: 500px;
  grid-gap: 5px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
`;
