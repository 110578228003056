import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const TeaserComponent = styled.div`
  position: ${props => (props.pageEditing ? 'relative' : 'fixed')};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: ${props => props.theme.colors.named.white};
  visibility: ${props => (props.isMounted ? 'visible' : 'hidden')};

  ${breakpoints.tabletLandscape} {
    overflow: hidden;
    padding-bottom: ${props => (props.pageEditing ? props.theme.px(30) : 0)};
  }
`;

export const Layout = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: ${props => (props.hideScroll ? 'hidden' : 'scroll')};
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TeaserTextPattern = styled.div`
  padding-top: ${props =>
    props.wholeHeaderHeight ? `calc(${props.wholeHeaderHeight}px + 5vh)` : props.theme.px(120)};

  ${breakpoints.tabletLandscape} {
    padding-top: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: ${props => (props.wholeHeaderHeight ? `calc(${props.wholeHeaderHeight}px + 30%)` : '45%')};
  }

  ${breakpoints.desktopMedium} {
    top: 50%;
  }
`;

export const TeaserTextLine = styled.div`
  position: relative;
  height: ${props => props.theme.px(50)};

  ${breakpoints.tabletLandscape} {
    height: auto;
  }

  .h1 {
    margin: 0;
    white-space: nowrap;
    font-size: ${props => props.theme.px(55)};
    line-height: ${props => props.theme.px(46)};
    letter-spacing: normal;
    font-weight: 700;
    font-family: ${props => props.theme.typography.typefaces.headings};
    transform: translateX(calc(${props => props.offset * 5 - 50}%));
    position: absolute;
    left: 50%;
    letter-spacing: 0;

    ${breakpoints.tabletLandscape} {
      position: relative;
      transform: translateX(${props => props.offset * 10}vw);
      left: auto;
      font-size: ${props => props.theme.px(120)};
      line-height: ${props => props.theme.px(100)};
      margin-bottom: ${props => props.theme.px(30)};
    }

    ${breakpoints.desktopLarge} {
      font-size: ${props => props.theme.px(231)};
      line-height: ${props => props.theme.px(198)};
      margin-bottom: ${props => props.theme.px(40)};
    }
  }
`;

export const TeaserText = styled.span`
  display: inline;
  margin: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: ${props => (props.highlight ? props.theme.colors.named.black : props.theme.colors.grays[100])};
`;

export const CTAWrapper = styled.div`
  padding: ${props =>
    props.theme.px({
      top: 30,
      right: 30,
      bottom: 20,
      left: 30,
    })};
  margin: auto;
  display: flex;
  justify-content: center;

  ${breakpoints.tabletPortrait} {
    padding: 0;
    position: absolute;
    top: ${props =>
      props.wholeHeaderHeight ? `calc(${props.wholeHeaderHeight}px + 21%)` : 'calc(100% - 100px)'};
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  ${breakpoints.tabletLandscape} {
    padding: 0;
    position: absolute;
    top: ${props =>
      props.wholeHeaderHeight ? `calc(${props.wholeHeaderHeight}px + 60%)` : 'calc(100% - 100px)'};
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
`;

export const AdWrapper = styled.div`
  padding: ${props => props.theme.px(30)};
  margin: auto;
  display: flex;
  justify-content: center;

  ${breakpoints.tabletLandscape} {
    padding: ${props =>
      props.theme.px({
        top: 0,
        right: 15,
        bottom: 15,
        left: 0,
      })};
    position: absolute;
    bottom: 0;
    right: 0;
    justify-content: right;
  }

  ${breakpoints.desktopMedium} {
    padding: ${props =>
      props.theme.px({
        top: 0,
        right: 30,
        bottom: 30,
        left: 0,
      })};
  }
`;

export const RevealComponent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  visibility: ${props => (props.isMounted ? 'visible' : 'hidden')};
`;

export const RevealLayout = styled.div`
  background-color: #e41165;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const RevealText = styled.h1`
  font-size: ${props => props.theme.px(78)};
  line-height: ${props => props.theme.px(76)};
  letter-spacing: -1.56px;
  color: ${props => props.theme.colors.named.white};
  margin: 0;
  width: 100%;
  text-align: center;
  white-space: nowrap;

  ${breakpoints.tabletLandscape} {
    font-size: ${props => props.theme.px(200)};
    line-height: ${props => props.theme.px(200)};
    letter-spacing: -3px;
  }

  ${breakpoints.desktopLarge} {
    font-size: ${props => props.theme.px(301)};
    line-height: ${props => props.theme.px(291)};
    letter-spacing: -6px;
  }
`;

export const LayerImage = styled.img`
  position: absolute;
  right: 0;
  top: ${props => props.theme.px(40)};
  width: 40%;

  ${breakpoints.desktopSmall} {
    width: 30%;
  }
`;
