import { breakpoints } from '../../global-styles/breakpoints';
import styled, { css } from 'styled-components';

export const SocialShareComponent = styled.div`
padding-bottom: ${props => props.theme.px(30)};
padding-top: ${props => props.theme.px(30)};
`;

export const ShareButton = styled.a`
  position: relative;
  height: auto;
  width: fit-content;
  border: #919191 solid 1px;
  padding: 0;
  display: flex;  
  justify-content: flex-start;
  align-items: flex-start   ;
  color: #000000;
  text-decoration: none;
  text-align: center;
  background: none;
  transform: translateZ(0);
  cursor: pointer;
  filter:  drop-shadow(0px 0px 0px #00ffc5 8c);
  transition: filter 0.25s;
  appearance: none;

  .icon-container {
    display: flex;
  }

  .icon-container {
    path:first-child,
    circle,
    #Path_1418
    {
     fill: #00ffc5;;
     stroke: #000000;
    }
    display: flex;
    padding: 0;
    height: 60px;
    align-items: center;
  }

  &:hover {
    /* path:first-child,
      circle,
      #Path_1418 {
        fill: #000000;
        stroke: #00ffc5;;
    }

    path:not(:first-child) {
      fill: #00ffc5;;
      stroke: #000000;
    } */
    filter: drop-shadow(0px 5px 3px #00ffc5 8c);
    background: #f5f5f5;
  }
`;

export const Label = styled.p`
font-size: 14px;
font-weight: 700;
text-decoration: none;
margin: auto;
padding-left: 15px;
padding-right: 15px;

${breakpoints.tabletLandscape} {
  font-size: 22px;
}


`;


