import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: relative;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  visibility: ${props => (props.isMounted ? 'visible' : 'hidden')};
`;

export const VideoWrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: sticky;
  top: 0;
  margin: auto;
  transform: scale(0.75);
`;
