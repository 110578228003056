import React from 'react';
import AmbientVideoComponent from 'lmel-components/core/ambient-video';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const AmbientVideo = props => {
  return (
    <ErrorBoundary>
      <AmbientVideoComponent {...props} />
    </ErrorBoundary>
  );
};

export default withSitecoreContext()(AmbientVideo);
